import { EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import axios from "axios";
import { Observable } from "rxjs";
import { storagetGet, storagetSet } from "./storageHelpers.ts";
import { updatePickedUpTicket } from "./userHelpers.ts";
//import { EndUserService } from "../backend/api/end-user.service";
//import { LoggerService } from "./loger.service";
// import { StorageService } from "./storage.service";

export class BlueToothService {
  title = "naerboks-web-app";
  hasPickupPieces: boolean = false;
  hasDeliveredPieces: boolean = false;
  authChallenge: string = "";
  authChallenges: Array<any>;
  authCredentials: Number = 0;
  private challangeAuthorized: boolean = false;
  token: string = "";
  private uuid: string = "";
  private completed: boolean = false;

  services: any;
  selectedDevice: any;
  gattServer: any;
  gattOpenBoxCharacteristic: any;
  private deviceConnected: boolean = false;

  OPEN_BOX_INPUT_CHARACTERISTIC_UUID = "18fa8492-0ac4-8421-0677-329c013f34a0";
  OPEN_BOX_OUTPUT_PLAIN_CHARACTERISTIC_UUID = "18fa8494-0ac4-8421-0677-329c013f34a0";
  OPEN_BOX_OUTPUT_ENCRYPTED_CHARACTERISTIC_UUID = "18fa8493-0ac4-8421-0677-329c013f34a0";
  OPTIONAL_SERVICE = "18fa8491-0ac4-8421-0677-329c013f34a0";

  navigator: any = window.navigator;
  chunkToWrite: any;
  compartmentId: any;
  flowEventsProcess: any = new EventEmitter();
  selectedParcel: any;
  authChalengeResponse: string = "";

  // Recieve data from BLE
  totalReceived: number = 0;
  encrypted_response_started: boolean = false;
  tokenLength: number = 0;
  bleReturnToken: any;
  pickedUpParcels: Array<any> = [];
  doorClosed: boolean = true;
  tryToOpenDoorCounter: number = 0;
  rawRecievedData: Array<any> = [];
  pickedParcelEvents: object = {};
  doorOpenCounter: number = 0;
  private currentParcelCounts: number = 0;
  private reOpenCount: number = 0;

  counterAfterDoorOpen: any;
  counterSecondsAfterDoorOpen: number = 0;
  pickupJson = {};
  onFinish: any;
  pieceId: string;
  sendSuccess: boolean = false;

  constructor(onFinish, private route: ActivatedRoute) {
    this.onFinish = onFinish;
  }

  async setDataAndRunFlow(uid, json, pieceId) {
    this.pieceId = pieceId;
    await this.slmFullFlow(uid, json);
  }

  public getTimer(): Observable<any> {
    const timeObservAble = new Observable((observer) => {
      this.counterAfterDoorOpen = setInterval(() => {
        this.counterSecondsAfterDoorOpen++;
        observer.next({
          process: "door_open_countdown",
          data: this.counterSecondsAfterDoorOpen,
          status: true,
        });
      }, 1000);
    });

    return timeObservAble;
  }

  isDoorOpen() {
    return this.doorClosed ? false : true;
  }

  getParcelCount() {
    return this.currentParcelCounts;
  }

  setParcelCount(v: number) {
    this.currentParcelCounts = v;
  }

  isToken() {
    return this.token ? true : false;
  }

  resetDevice() {
    this.disconnect();
    this.selectedDevice = null;
    this.gattServer = null;
  }

  updateParcelCount(dir: string = "add") {
    switch (dir) {
      case "sub":
        this.currentParcelCounts--;
        break;
      case "add":
        this.currentParcelCounts++;
        break;
    }
    if (this.currentParcelCounts < 0) this.currentParcelCounts = 0;
  }

  isDeviceConnected() {
    return this.deviceConnected ? true : false;
  }

  isAuthChallenged() {
    return this.challangeAuthorized ? true : false;
  }

  isDeviceSelected() {
    if (this.selectedDevice) return true;
    else return false;
  }

  isDoorClosed() {
    return this.doorClosed ? true : false;
  }

  closedDoor() {
    this.doorClosed = true;
  }

  openedDoor() {
    this.doorClosed = false;
  }

  setUUID(uuid: string) {
    this.uuid = uuid;
  }

  isUUID() {
    return this.uuid ? true : false;
  }

  setParcel(p) {
    this.selectedParcel = p;
  }

  getParcel() {
    return this.selectedParcel;
  }

  async syncData(dataArray) {
    // Data is an array of numbers from swipbox locker resp
    let plainData = {
      piece_id: this.pieceId,
      data: "" + dataArray,
      ts: `${Date.now()}`,
    };
    let data = {
      uid: this.uuid,
      piece_ids: this.pieceId,
      tokens: dataArray,
      plain_data: [plainData],
      // sdk_log: "",
    };
    let params = {
      request_type: "tokens",
      data: [data],
    };
    let body = {
      patch_data: [params],
      order_id: this.pieceId,
    };
    try {
      await axios.post(
        `https://api.skroutzdelivery.gr/external/swipBoxWebBluetooth/postData/${this.pieceId}`,
        body,
      );
    } catch (e) {}
  }

  setCredentials(obj: any) {
    if (obj.token) {
      this.token = obj.token;
    }

    if (obj.auth) {
      this.authChallenge = obj.auth[0]["challenge"];
      this.authChalengeResponse = obj.auth[0]["response"];
      this.authChallenges = obj.auth;
    }

    if (obj.compartment_id) {
      this.compartmentId = obj.compartment_id;
    }
  }

  async connectDevice() {
    if (this.isWebBleAvailable()) {
      console.log("getDeviceInfo::triggered");
      await this.getDeviceInfo(this.uuid.toLowerCase());
    }
  }

  checkAvailability() {
    return new Promise((resolve, reject) => {
      if (typeof this.navigator.bluetooth.getAvailability === "function") {
        this.navigator.bluetooth.getAvailability().then((b: any) => {
          resolve(b);
        });
      } else resolve(true);
    });
  }

  isWebBleAvailable() {
    if (!this.navigator.bluetooth) {
      console.log("Web Bluetooth is not available!");
      return false;
    }
    return true;
  }

  writeTokenForDoor() {
    if (!this.isToken()) {
      console.log("Empty Token");
      this.flowEventsProcess.emit({
        process: "readDevice:device",
        data: { message: "Empty token", code: 2 },
        status: false,
      });
      return;
    }
    // console.log("Writing Token");

    let tokenBytes = this.base64ToArrayBuffer(20, this.token);
    this.flowEventsProcess.emit({
      process: "writeDevice:token",
      data: { token: tokenBytes },
      status: true,
    });
    this.processPickedParcelEvents(true, "tokenSent");
    this.startWriting(0, 20, tokenBytes);
  }

  writeAuthChallange() {
    console.log("Writing Auth challenge");
    let tokenBytes = this.base64ToArrayBuffer(20, this.authChallenge);

    setTimeout(() => {
      if (!this.isAuthChallenged()) {
        this.startWriting(0, 20, tokenBytes);
        ///this.onDataReceived('5sfdsfd');
      } else this.writeTokenForDoor();
    }, 1000);
  }

  async gattServerConnect(openLocker: boolean = false) {
    console.log("Getting GATT connect...");

    let server;
    let service;
    let characteristic;
    try {
      server = await this.selectedDevice.gatt.connect();
    } catch (error) {
      this.flowEventsProcess.emit({
        process: "gattConnect:device",
        data: error,
        status: false,
      });
      console.log("Request device error: " + error);
    }
    try {
      this.flowEventsProcess.emit({
        process: "requestGattService:server",
        data: server,
        status: true,
      });
      this.deviceConnected = true;
      this.gattServer = server;
      service = await server.getPrimaryService(this.OPTIONAL_SERVICE);
    } catch (error) {
      this.flowEventsProcess.emit({
        process: "gattConnect:device",
        data: error,
        status: false,
      });
      console.log("Request device error: " + error);
    }

    try {
      this.services = service;
      console.log("Getting GATT Plain Characteristic...");
      this.flowEventsProcess.emit({
        process: "requestGattPlainTextService:service",
        data: service,
        status: true,
      });
      characteristic = await service.getCharacteristic(
        this.OPEN_BOX_OUTPUT_PLAIN_CHARACTERISTIC_UUID,
      );
    } catch (error) {
      this.flowEventsProcess.emit({
        process: "gattConnect:device",
        data: error,
        status: false,
      });
      console.log("Request device error: " + error);
    }

    try {
      console.log("Plain Characteristic found........");
      characteristic.startNotifications();
      characteristic.addEventListener("characteristicvaluechanged", (event) => {
        // console.log("char changed", event.target.value);
        this.onDataReceived(event.target.value);
      });
      this.flowEventsProcess.emit({
        process: "characteristic:plain",
        data: characteristic,
        status: true,
      });

      characteristic = await this.services.getCharacteristic(
        this.OPEN_BOX_OUTPUT_ENCRYPTED_CHARACTERISTIC_UUID,
      );
    } catch (error) {
      this.flowEventsProcess.emit({
        process: "gattConnect:device",
        data: error,
        status: false,
      });
      console.log("Request device error: " + error);
    }

    try {
      console.log("Encrypted Characteristic found........");
      console.log("Start notifications......");
      characteristic.startNotifications();
      characteristic.addEventListener("characteristicvaluechanged", (event) => {
        console.log("Encrypted characteristicvaluechanged......");
        this.onDataReceived(event.target.value);
      });
      this.flowEventsProcess.emit({
        process: "characteristic:encrypted",
        data: characteristic,
        status: true,
      });
      characteristic = await this.services.getCharacteristic(
        this.OPEN_BOX_INPUT_CHARACTERISTIC_UUID,
      );
    } catch (error) {
      this.flowEventsProcess.emit({
        process: "gattConnect:device",
        data: error,
        status: false,
      });
      console.log("Request device error: " + error);
    }

    try {
      console.log("Open Box Characteristic found");
      console.log(characteristic);
      this.gattOpenBoxCharacteristic = characteristic;
      if (openLocker) this.writeAuthChallange();
    } catch (error) {
      this.flowEventsProcess.emit({
        process: "gattConnect:device",
        data: error,
        status: false,
      });
      console.log("Request device error: " + error);
    }
  }

  async getDeviceInfo(uuid: string) {
    let options = {
      filters: [
        {
          services: [uuid],
        },
      ],
      optionalServices: [this.OPTIONAL_SERVICE],
    };

    if (this.selectedDevice) {
      console.log("BLE Selected device ....");
      this.flowEventsProcess.emit({
        process: "requestDevice:device",
        data: this.selectedDevice,
        status: true,
      });
      return;
    }

    console.log("Requesting BLE device info...");
    await this.navigator.bluetooth
      .requestDevice(options)
      .then((device) => {
        // console.log(device);
        this.selectedDevice = device;
        // console.log("Name: " + this.selectedDevice.name);
        this.flowEventsProcess.emit({
          process: "requestDevice:device",
          data: device,
          status: true,
        });

        this.selectedDevice.addEventListener("gattserverdisconnected", (e: any) => {
          this.onDisconnected(e);
        });
      })
      .catch((error) => {
        this.flowEventsProcess.emit({
          process: "requestDevice:device",
          data: error,
          status: false,
        });
        console.log("Request device error: " + error);
      });
  }

  startWriting(index, chunkSize, tokenBytes) {
    if (index * chunkSize < tokenBytes.length) {
      if (index * chunkSize + chunkSize < tokenBytes.length)
        this.chunkToWrite = tokenBytes.slice(index * chunkSize, index * chunkSize + chunkSize);
      else this.chunkToWrite = tokenBytes.slice(index * chunkSize, tokenBytes.length);
      this.gattOpenBoxCharacteristic?.writeValue(this.chunkToWrite).then((_) => {
        this.startWriting(++index, 20, tokenBytes);
      });
    }
  }

  getBytes(str: string) {
    var bytes: number[] = [];
    for (var i = 0; i < str.length; ++i) {
      bytes.push(str.charCodeAt(i));
    }
    return bytes;
  }

  validateAuthChallenge(locker_data): boolean {
    let challengeAnswer = this.getBytes(atob(this.authChalengeResponse)).join(",");

    if (challengeAnswer && challengeAnswer.length > 0) {
      if (locker_data[1] === challengeAnswer.split(",").length) {
        let lockerAnswer = locker_data.join().split(",");
        lockerAnswer.splice(0, 2);

        if (challengeAnswer === lockerAnswer.join()) {
          return true;
        }
      }
    }

    return false;
  }

  doorOpenExist(piece_id: any) {
    let deviceEvents = storagetGet("deviceEvents");
    let events: Array<any> = JSON.parse(deviceEvents as string);
    if (events[piece_id] && events[piece_id]["door_open"]) return true;

    return false;
  }

  onDataReceived(value: any) {
    let data = new Uint8Array(value.buffer);
    this.rawRecievedData.push(data);
    console.log(" ...... onDataReceived ......");
    // Auth challenge response
    if (data[0] === 5) {
      console.log(".... Validation Start ......");

      if (this.validateAuthChallenge(data)) {
        console.log(".......challenged passed ......");
        this.challangeAuthorized = true;
        this.writeTokenForDoor();
      } else {
        console.log(".......challenged failed ......");
      }
      return;
    }
    // Return token C2 processing....
    if (data.length !== 4 || (data[0] > 3 && data[1] !== 2)) {
      this.bleTokenProcess(data);
      // this.syncData(data);
      return "skata";
    }

    //data [1,2,box number, 1/0 (open/close)]
    else if (data[0] === 1 && data[2] === this.compartmentId && data[3] === 0) {
      //   this.logger.info(this.className, `Door close event received`);
      console.log("..... Door closed .....");
      this.closedDoor();
      this.processPickedParcelEvents(data.join(","), "door_close");
      //convert comma separated string to base64 encoded data
      this.flowEventsProcess.emit({
        process: "doorclosed:device",
        data: data.join(","),
        status: true,
      });
    }
    //door open event received
    else if (data[0] === 1 && data[2] === this.compartmentId && data[3] === 1) {
      //   this.hasReceivedData = 1;
      this.doorOpenCounter++;
      this.openedDoor();
      this.tryToOpenDoorCounter = 0;
      //   let currentTime = new Date().getTime().toString();
      this.processPickedParcelEvents(data.join(","), "door_open");
      this.getTimer().subscribe((re: any) => {
        if (re.data > 4) {
          console.log("Before clear counter");
          this.updateShipmentSatus();
          clearInterval(this.counterAfterDoorOpen);
          console.log(`..... Clear counter .....`, this.counterAfterDoorOpen);
        }
        console.log(`..... Counter ..... ${JSON.stringify(re)}`);
        switch (re.process) {
          case "door_open_countdown":
            this.flowEventsProcess.emit({
              process: "door_open_countdown",
              data: this.counterSecondsAfterDoorOpen,
              status: true,
            });
            break;
        }
      });

      //convert comma separated string to base64 encoded data
      this.flowEventsProcess.emit({
        process: "dooropened:device",
        data: data.join(","),
        status: true,
      });
    }
    //incase of error show message
    else if (data[0] === 2 && data.length === 4) {
      let error_code = data[3];
      let error = "";
      switch (error_code) {
        case 0:
          error = "GENERIC ERROR";
          break;
        case 1:
          error = "INPUT DATA ERROR";
          break;
        case 2: // Token already used.
          error = "GUID ERROR";
          break;
        case 3:
          error = "TIMESTAMP ERROR";
          break;
        case 4:
          error = "RSA ENCRYPTION FAILED";
          break;
        case 5:
          error = "RSA DECRYPTION FAILED";
          this.reOpenCount++;
          break;
        case 6:
          error = "RSA SIGN FAILED";
          break;
        case 7:
          error = "RSA VERIFY FAILED";
          break;
        case 8:
          error = "AES ENCRYPTION FAILED";
          break;
        case 9:
          error = "AES DECRYPTION FAILED";
          break;
        case 10:
          error = "SHA FAILED";
          break;
        case 12:
          error = "INVALID SERVER KEY";
          break;
        case 16:
          error = "PARAM_ERROR";
      }
      if (error_code !== 3) {
        // this.selectedDevice.gatt.disconnect();
        this.flowEventsProcess.emit({
          process: "readDevice:device",
          data: { message: error, code: error_code },
          status: false,
        });
      }
      this.onFinish(error, this.doorOpenCounter > 0);
    }
  }

  bleTokenProcess(data: any) {
    //check if this is start of encrypted data
    // if (data[0] === 0 && (data[1] === 0 || data[1] === 64) && data[4] === 0 && data[5] === 64) {

    /**
     * Change in header for encrypted token
     * We have upgraded the RSA key to 1024 bits for better security.
     * This also results in an increased encrypted token size and thereby also an updated header.
     * Where the old version read “0,64,0,192,0,64”, the new header will read 0,128,0,192,0,128”.
     */
    if (
      (data[0] === 0 && (data[1] === 0 || data[1] === 64) && data[4] === 0 && data[5] === 64) ||
      ((data[1] === 0 || data[1] === 128) && data[4] === 0 && data[5] === 128)
    ) {
      let len = 70 + data[1];
      //mark the variables so we could track next chunk of incmoing data as part of this token
      this.totalReceived = 0;
      this.encrypted_response_started = true;
      this.tokenLength = data[3] | (data[2] << 8);
      this.tokenLength += len;
      // console.log("this.tokenLength = " + this.tokenLength);
      this.bleReturnToken = new Uint8Array(this.tokenLength);
    }
    //   //if the incoming data is part of encrypted token
    if (this.encrypted_response_started) {
      //get the array out of buffer
      //append it to our Uint8Array
      this.bleReturnToken.set(data, this.totalReceived);
      //increament the total data received counter
      this.totalReceived += data.byteLength;
      //output the chunk in console
      // console.log("data = " + data.join(","));
      // console.log("totalReceived = " + this.totalReceived);
      // this.logger.info(this.className, `Total token bytes received from hardware: ${this.totalReceived} `);
    }

    //   //if the incoming data is part of encrypted token and we have received full data
    if (this.encrypted_response_started && this.totalReceived === this.tokenLength) {
      //     this.logger.info(this.className, `All token bytes received, total bytes: ${this.totalReceived} `);
      //     //reset the variables
      this.encrypted_response_started = false;
      this.totalReceived = 0;
      this.tokenLength = 0;
      console.log("-----------ALL DONE----------");
      //     //get the array buffer and convert it to comma separate string
      // let comma_sep_string = this.bleReturnToken.join(",");
      // console.log("-----------Comma seperated buffed token----------");
      // console.log(comma_sep_string);
      let encoded_string = String.fromCharCode.apply(null, this.bleReturnToken);
      //     //convert comma separated string to base64 encoded data
      let base64Encoded = btoa(encoded_string);
      // console.log("-----------base64Encoded Encoded Token----------");
      // console.log(base64Encoded);
      this.storeC2Token(base64Encoded);
    }
  }

  onDisconnected(e: any) {
    console.log("Device disconnected");
    this.deviceConnected = false;
    this.challangeAuthorized = false;
    this.flowEventsProcess.emit({
      process: "disconnected:device",
      data: "",
      status: true,
    });
  }

  updateShipmentSatus(index: number = 0) {
    return new Promise(async (resolve, reject) => {
      let pickedParcel = this.parcePickedParcelForSync();
      if (!pickedParcel || pickedParcel.length < 1) {
        resolve(null);
        return;
      }
      this.sendSuccess = true;
      await updatePickedUpTicket(pickedParcel).then(
        (re: any) => {
          this.completed = true;
          let DataKey: any = "";
          let pieceId = "";
          let SynchPieceIDs: Array<any> = storagetGet("synchRonizedIds")
            ? JSON.parse(storagetGet("synchRonizedIds") as string)
            : [];

          pickedParcel.forEach((parcel: any) => {
            DataKey = parcel.data[0];
            pieceId = DataKey.piece_ids[0];
            SynchPieceIDs.push(pieceId);
            this.pickedUpParcels.forEach((picked: any, n: number) => {
              if (picked["piece_id"] === pieceId) this.pickedUpParcels[n]["sync"] = true;
            });
          });

          storagetSet("pickedParcels", JSON.stringify(this.pickedUpParcels));
          storagetSet("synchRonizedIds", JSON.stringify(SynchPieceIDs));
          console.log("...... Parcel update Success .....");
          resolve(true);
        },
        (err: any) => {
          console.log("...... Parcel update Error .....");
          console.log(err);
          resolve(false);
        },
      );
    });
  }

  disconnect() {
    if (!this.selectedDevice) {
      return;
    }
    console.log("Disconnect Name: " + this.selectedDevice);
    console.log("Disconnecting from Bluetooth Device...");
    if (this.selectedDevice.gatt.connected) {
      this.selectedDevice.gatt.disconnect();
    } else {
      console.log("Bluetooth Device is already disconnected");
    }
  }

  base64ToArrayBuffer(chunkSize, base64) {
    let binary_string = atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len + 1);
    bytes[0] = chunkSize;
    for (let i = 1; i < len + 1; i++) {
      bytes[i] = binary_string.charCodeAt(i - 1);
    }
    return bytes;
  }

  getc2Token(piece_id: string) {
    let c2Tokens: any = storagetGet("c2Tokens");
    let tokens: object = {};

    if (c2Tokens) {
      tokens = JSON.parse(c2Tokens);
    }

    if (tokens[piece_id]) {
      return tokens[piece_id];
    }
    return "";
  }

  getEventValue(piece_id: string) {
    let events: any = storagetGet("deviceEvents");

    if (events) this.pickedParcelEvents = JSON.parse(events);

    if (this.pickedParcelEvents[piece_id]) {
      return this.pickedParcelEvents[piece_id];
    }
    return "";
  }

  getPickedParcels() {
    let StorageData: any = storagetGet("pickedParcels");
    return StorageData ? JSON.parse(StorageData) : [];
  }

  storePickedParcel(parcel: any) {
    let StorageData: any = storagetGet("pickedParcels");
    this.pickedUpParcels = StorageData ? JSON.parse(StorageData) : [];
    let exist: boolean = false;

    if (this.pickedUpParcels && this.pickedUpParcels.length) {
      this.pickedUpParcels.forEach((v: any) => {
        if (parcel.piece_id === v.piece_id) exist = true;
      });
    }

    if (!exist) this.pickedUpParcels.push(parcel);

    storagetSet("pickedParcels", JSON.stringify(this.pickedUpParcels));
  }

  parcePickedParcelForSync() {
    let tempData: Array<any> = this.getPickedParcels();
    let syncData: Array<any> = [];
    let data: any = {};
    let c2token: string = "";
    let plain_data: any = {};
    tempData.forEach((element) => {
      plain_data = this.getEventValue(element.piece_id);
      c2token = this.getc2Token(element.piece_id);
      data = {
        uid: element.uid,
        piece_ids: [element.piece_id],
        tokens: [c2token],
        plain_data: [plain_data["door_open"]],
      };
      // console.log("......Saved synch element .....", element);
      if (!element.sync && plain_data.tokenSent) {
        syncData.push({
          request_type: "tokens",
          data: [data],
        });
      }
    });
    return syncData;
  }

  processPickedParcelEvents(e: any, type: string) {
    let events = storagetGet("deviceEvents");
    if (events) this.pickedParcelEvents = JSON.parse(events);
    /* eslint-disable no-fallthrough */
    switch (type) {
      case "tokenSent":
        if (this.pickedParcelEvents[this.getParcel().piece_id]) {
          this.pickedParcelEvents[this.getParcel().piece_id][type] = true;
        } else {
          this.pickedParcelEvents[this.getParcel().piece_id] = {};
          this.pickedParcelEvents[this.getParcel().piece_id][type] = true;
        }
        break;
      case "door_open":
      case "door_close":
        if (this.pickedParcelEvents[this.getParcel().piece_id]) {
          this.pickedParcelEvents[this.getParcel().piece_id][type] = {
            piece_id: this.getParcel().piece_id,
            data: e,
            ts: new Date().getTime().toString(),
          };
        } else {
          this.pickedParcelEvents[this.getParcel().piece_id] = {};
          this.pickedParcelEvents[this.getParcel().piece_id][type] = {
            piece_id: this.getParcel().piece_id,
            data: e,
            ts: new Date().getTime().toString(),
          };
        }

        // When door is open ....
        this.storePickedParcel({
          sync: false,
          uid: this.uuid,
          piece_id: this.getParcel().piece_id,
          tokens: [],
          plain_data: {
            piece_id: this.getParcel().piece_id,
            data: "",
            ts: new Date().getTime().toString(),
          },
        });

        break;
    }
    /* eslint-enable no-fallthrough */
    console.log("............ End Process Event ...........");
    storagetSet("deviceEvents", JSON.stringify(this.pickedParcelEvents));
    if (this.completed) {
      this.onFinish("success");
    }
  }

  storeC2Token(c2Token: string) {
    let temp: any = storagetGet("c2Tokens");
    let deliveredTokens: object = temp ? JSON.parse(temp) : {};
    let piece_id = this.getParcel().piece_id;
    deliveredTokens[piece_id] = c2Token;
    storagetSet("c2Tokens", JSON.stringify(deliveredTokens));
  }

  async slmFullFlow(uid, pickupJson) {
    this.setUUID(uid);
    this.setParcel({ piece_id: this.pieceId });
    await this.setCredentials(pickupJson);
    await this.connectDevice();
    let a = await this.gattServerConnect(true);
    console.log("disconnected", a);
  }
}
