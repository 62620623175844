export const WelcomeText = (lang: string): string => {
  if (lang.includes("el")) {
    return "Καλως ήρθες!";
  } else {
    return "Welcome!";
  }
};

export const TipsText = (lang: string, isReturn: boolean = false): string => {
  if (lang.includes("el")) {
    return isReturn ? "Επιστροφή με 3 απλά βήματα:" : "Παραλαβή με 3 απλά βήματα:";
  } else {
    // return 'Pick up in 3 simple steps:';
    return isReturn ? "Return in 3 simple steps:" : "Pick up in 3 simple steps:";
  }
};

export const ApproachLocker = (lang: string): string => {
  if (lang.includes("el")) {
    return "Πλησίασε το locker";
  } else {
    return "Approach locker";
  }
};

export const BluetoothOpenText = (lang: string): string => {
  if (lang.includes("el")) {
    return "Ενεργοποίησε το bluetooth";
  } else {
    return "Τurn on bluetooth";
  }
};

export const ClickButtonText = (lang: string, isReturn: boolean = false): string => {
  if (lang.includes("el")) {
    // return 'Πάτα Παραλαβή';
    return isReturn ? "Πάτα Επισροφή" : "Πάτα Παραλαβή";
  } else {
    return isReturn ? "Click Return" : "Click Pick up";
  }
};

export const GetPackageText = (lang: string): string => {
  if (lang.includes("el")) {
    return "Πάρε το δέμα σου!";
  } else {
    return "Get your package!";
  }
};

export const GreetingsText = (lang: string): string => {
  if (lang.includes("el")) {
    return "Kαλώς ήρθες";
  } else {
    return "Welcome";
  }
};

export const LockerOpenText = (lang: string, isReturn: boolean = false): string => {
  if (lang.includes("el")) {
    return isReturn ? "Επισροφή!" : "Παραλαβή!";
  } else {
    return isReturn ? "Return!" : "Collect!";
  }
};

export const LockerReOpenText = (lang: string, isReturn: boolean = false): string => {
  if (lang.includes("el")) {
    return isReturn ? "Επισροφή !" : "Παραλαβή !";
  } else {
    return isReturn ? "Return !" : "Collect !";
  }
};

export const ErrorText = (lang: string, error: string, showRetry: boolean): string => {
  if (showRetry) {
    return RetryErrorText(lang);
  }
  if (lang.includes("el")) {
    return `Κάτι πήγε στραβά, προσπάθησε ξανά`;
    // return `Κάτι πήγε στραβά, προσπάθησε ξανά. \n ${error}`;
  } else {
    return "Something is wrong, try again";
  }
};

export const RetryErrorText = (lang: string): string => {
  if (lang.includes("el")) {
    return `Παρακαλώ περίμενετε...`;
  } else {
    return "Please wait...";
  }
};

export const FinalErrorText = (lang: string, error: string, isReturn: boolean = false): string => {
  console.log(error);
  if (lang.includes("el")) {
    const word = isReturn ? "επιστρέψεις" : "παραλάβεις";
    return `Δυστυχώς δεν θα μπορέσεις να ${word} το πακέτο σου`;
  } else {
    const word = isReturn ? "return" : "collect";
    return `Unfortunatelly you will not be able to ${word} your package`;
  }
};

export const SuccessText = (lang: string): string => {
  if (lang.includes("el")) {
    return "🎉";
  } else {
    return "🎉";
  }
};

export const LoadingText = (lang: string): string => {
  if (lang.includes("el")) {
    return "Παρακαλώ περίμενε...";
  } else {
    return "Please wait...";
  }
};

export const IOSText = (lang: string, isReturn: boolean = false): string => {
  if (lang.includes("el")) {
    const word = isReturn ? "επιστρέψετε" : "παραδώσετε";
    return `Δυστυχώς δεν μπορείτε να ${word} με συσκευή iOS`;
  } else {
    const word = isReturn ? "return" : "collect";
    return `Unfortunatelly you are unable to ${word} with iOS device`;
  }
};

export const ChromeText = (lang: string): string => {
  if (lang.includes("el")) {
    return "Η σελίδα λειτουργεί μόνο μέσω Chrome Browser";
  } else {
    return "Page works only on Chrome Browser";
  }
};
