//import logo from './logo.svg';
import "./App.css";
import React, { Component, useState } from "react";
import { BlueToothService } from "./bluetooth.service.ts";
import LoadingWrapper from "./LoadingWrapper";
import BackgroungImage from "../src/assets/images/main-bg.svg";
import {
  GreetingsText,
  LockerOpenText,
  ErrorText,
  TipsText,
  BluetoothOpenText,
  ClickButtonText,
  FinalErrorText,
  LockerReOpenText,
  ApproachLocker,
  SuccessText,
  IOSText,
  ChromeText,
} from "./helpers/text.ts";
import axios from "axios";

function App() {
  // eslint-disable-next-line no-unused-vars
  const [language, setLanguage] = useState(navigator.language || navigator.userLanguage);
  let isReturn = false;
  if (window.location.pathname.includes("/return")) {
    isReturn = true;
  }
  const hashedId = window.location.href.split("/").pop();
  const urlParams = hashedId.split("?")[1];
  let autoLinkFromGuide = false;
  if (!!urlParams && urlParams.length > 0) {
    const params = urlParams.split("&");
    const paramsObj = {};
    params.forEach((param) => {
      paramsObj[param.split("=")[0]] = param.split("=")[1];
    });
    if (paramsObj["auto"] === "true") {
      autoLinkFromGuide = true;
    }
  }
  return (
    <div className="App">
      <header className="App-header">
        <h3>{GreetingsText(language)}</h3>
        <Bt language={language} isReturn={isReturn} autoLinkFromGuide={autoLinkFromGuide} />
      </header>
    </div>
  );
}

export default App;

class Bt extends Component {
  sboxService = null;
  voucher = null;
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      errorMessage: "",
      showReopen: false,
      autoLinkFromGuide: false,
      showButton: true,
      showFinalError: false,
      showSuccess: false,
      showRetryError: false,
      attempts: 0,
    };
    this.onClick = this.onClick.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  markAsDelivered() {
    axios.post(
      `https://api.skroutzdelivery.gr/external/swipBoxWebBluetooth/markAsDelivered/${this.voucher}`,
      { auto_link: this.props.autoLinkFromGuide },
    );
  }

  async getData(refetch) {
    let res;
    if (refetch) {
      try {
        res = await axios.get(
          `https://api.skroutzdelivery.gr/external/swipBoxWebBluetooth/generateNewTokenForWebBluetooth/${this.voucher}`,
        );
      } catch (e) {
        this.onFinish("final");
      }
    } else {
      try {
        res = await axios.get(
          `https://api.skroutzdelivery.gr/external/swipBoxWebBluetooth/getLockerDataForWebBluetooth/${this.voucher}`,
        );
      } catch (e) {
        this.onFinish("final");
      }
    }
    return [res.data.lockerUUID, res.data.receiveJson, res.data.pieceId];
  }

  async onFinish(error) {
    let showReopen = true;
    let showFinalError = false;
    let showSuccess = false;
    let showRetryError = false;
    if (this.state.attempts > 2) {
      showFinalError = true;
      showReopen = false;
    } else if (["GUID ERROR", "init_fail", "RSA DECRYPTION FAILED"].includes(error)) {
      showReopen = false;
      showRetryError = true;
      const data = await this.getData(true);

      try {
        await this.sboxService.setDataAndRunFlow(data[0], data[1], data[2]);
      } catch (e) {
        this.onFinish("refetch_error");
      }
    } else if (error === "success") {
      showFinalError = false;
      showReopen = false;
      showSuccess = true;
      await this.markAsDelivered();
    } else if (error === "final" || error === "refetch_error") {
      showFinalError = true;
      showReopen = false;
    }

    this.setState({
      loaded: true,
      errorMessage: error,
      showButton: false,
      showReopen: showReopen,
      showFinalError: showFinalError,
      attempts: this.state.attempts + 1,
      showSuccess: showSuccess,
      showRetryError: showRetryError,
    });
  }

  async onClick() {
    this.setState({
      loaded: false,
      error: "",
    });
    let voucher = window.location.pathname.replace("/", "").replace("return/", "");
    // window.sbox = new BlueToothService();
    let sboxService = new BlueToothService(this.onFinish);
    this.sboxService = sboxService;
    this.voucher = voucher;
    const data = await this.getData(false);

    try {
      await sboxService.setDataAndRunFlow(data[0], data[1], data[2]);
    } catch (e) {
      this.onFinish("init_fail");
    }
  }

  isIOS() {
    return (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
        navigator.platform,
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  isChrome() {
    return (
      !!window.chrome &&
      (!!window.chrome.webstore || !!window.chrome.runtime || !!window.chrome.csi)
    );
  }

  render() {
    const isChrome = this.isChrome();
    const isIOS = this.isIOS();

    return (
      <div
        style={{
          background: ` url(${BackgroungImage}) bottom center`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          flex: 1,
        }}
      >
        {isIOS && <h4>{IOSText(this.props.language, this.props.isReturn)}</h4>}
        {isChrome && isIOS === false ? (
          <LoadingWrapper
            loaded={this.state.loaded}
            showCounter={true}
            language={this.props.language}
          >
            <h4>{TipsText(this.props.language, this.props.isReturn)}</h4>
            <ul style={{ display: "inline-block", textAlign: "left" }}>
              <li>{ApproachLocker(this.props.language)}</li>
              <li>{BluetoothOpenText(this.props.language)}</li>
              <li>{ClickButtonText(this.props.language, this.props.isReturn)}</li>
              {/* <li>{GetPackageText(this.props.language)}</li> */}
            </ul>
            <br />
            {this.state.showSuccess && (
              <div>
                <h3>{SuccessText(this.props.language)}</h3>
              </div>
            )}

            {this.state.showFinalError && this.state.showSuccess === false && (
              <div>
                <h3>
                  {FinalErrorText(
                    this.props.language,
                    this.state.errorMessage,
                    this.props.isReturn,
                  )}
                </h3>
              </div>
            )}
            {this.state.errorMessage &&
              this.state.showFinalError === false &&
              this.state.showSuccess === false && (
                <div>
                  <h3>
                    {ErrorText(this.props.language, this.state.errorMessage, this.showRetryError)}
                  </h3>
                </div>
              )}
            {this.state.showReopen === false && this.state.showSuccess === false && (
              <button onClick={this.onClick} className="button-special">
                {LockerOpenText(this.props.language, this.props.isReturn)}
              </button>
            )}
            {this.state.showReopen && this.state.showSuccess === false && (
              <button onClick={this.onClick} className="button-special">
                {LockerReOpenText(this.props.language, this.props.isReturn)}
              </button>
            )}
          </LoadingWrapper>
        ) : (
          <LoadingWrapper
            loaded={this.state.loaded}
            showCounter={true}
            language={this.props.language}
          >
            <h4>{ChromeText(this.props.language)}</h4>
          </LoadingWrapper>
        )}
      </div>
    );
  }
}
