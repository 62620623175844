import React from "react";
import { CirclesWithBar } from "react-loader-spinner";
import { LoadingText } from "./helpers/text.ts";

const LoadingWrapper = (props) => {
  return (
    <div className="container">
      {props.loaded === false ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.showCounter && (
            <div style={{ display: "flex" }}>
              <span className="is-size-3">{LoadingText(props.language)}</span>
            </div>
          )}
          <br />
          <CirclesWithBar
            height="100"
            width="100"
            color="orange"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor=""
            innerCircleColor=""
            barColor=""
            ariaLabel="circles-with-bar-loading"
          />
        </div>
      ) : (
        props.children
      )}
    </div>
  );
};
export default LoadingWrapper;
